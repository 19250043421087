// Team Members
import Member1 from '../../../images/team/member1.svg';
import Member2 from '../../../images/team/member2.svg';
import Member3 from '../../../images/team/member3.svg';

const Members = [
  {
    picture: Member1,
    name: 'Matías Simone',
    roleText: 'member1.role',
    linkedinURL: 'https://www.linkedin.com/in/simonematias/',
  },
  {
    picture: Member2,
    name: 'Agustín Pearson',
    roleText: 'member2.role',
    linkedinURL: 'https://www.linkedin.com/in/agustinpearson/',
  },
  {
    picture: Member3,
    name: 'Joaquín Scocozza',
    roleText: 'member3.role',
    linkedinURL: 'https://www.linkedin.com/in/joaquin-scocozza/',
  },
];

export default Members;

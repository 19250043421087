// Other companies
import company1 from '../../../images/companies/cfa.png';
import company2 from '../../../images/companies/cat.png';
import company3 from '../../../images/companies/nc.png';
import company4 from '../../../images/companies/miltrani.png';

const OtherCompanies = [
  {
    logo: company2,
    logoHeight: '68px',
    name: 'Compañía Argentina de Tierras',
    descText: 'company2.desc',
    website: 'https://www.cadetierras.com.ar/',
  },
  {
    logo: company3,
    logoHeight: '80px',
    name: 'Nicholson y Cano',
    descText: 'company3.desc',
    website: 'https://www.nicholsonycano.com.ar/',
  },
  {
    logo: company1,
    logoHeight: '40px',
    name: 'CFA',
    descText: 'company1.desc',
    website: 'https://www.cfafiduciaria.com/',
  },
  {
    logo: company4,
    logoHeight: '80px',
    name: 'Mitrani, Caballero & Ruiz Moreno',
    descText: 'company4.desc',
    website: 'https://www.mcolex.com/',
  },
];

export default OtherCompanies;

// Operators
import operator1 from '../../../images/companies/adecoagro.png';
import operator2 from '../../../images/companies/edp.png';
import operator3 from '../../../images/companies/espartina.png';

const OperatorsList = [
  {
    logo: operator1,
    descText: 'operator1.desc',
    website: 'https://www.adecoagro.com/',
  },
  {
    logo: operator2,
    descText: 'operator2.desc',
    website: 'https://edpagro.com.ar/',
  },
  {
    logo: operator3,
    descText: 'operator3.desc',
    website: 'https://www.espartina.com.ar/',
  },
];

export default OperatorsList;

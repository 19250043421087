import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CompanyItem from '../../../components/CompanyItem';
import OperatorsList from './operatorsList';

const Operators = (): JSX.Element => {
  const { t } = useTranslation('partners');

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      w="100%"
      px={{ base: 0, xs: 0, md: 60 }}
      py="96px"
      flexWrap={{ base: 'wrap' }}
    >
      <Flex w="100%" flexDirection="column" alignItems="center">
        <Flex
          overflow="hidden"
          direction="column"
          mr={{ base: 0 }}
          alignItems="center"
          width="100%"
          maxW="1126px"
        >
          <Box h="1px" w="100%" px="32px" bg="gray.200" mb={16} />
          <Heading as="h1" size={{ base: 'md', xs: 'xs' }} mb="24px">
            {t('our-operators')}
          </Heading>
        </Flex>

        <Flex overflow="hidden" direction="row" w="100%" maxW="1126px" justifyContent="center">
          <Flex
            alignItems="center"
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: '20px', sm: '100px', md: '220px', lg: '250px' }}
            mb={{ base: 0, sm: 10 }}
            filter="grayscale(1)"
          >
            {Array.from({ length: 3 }).map((_, index) => (
              <CompanyItem
                key={OperatorsList[index % OperatorsList.length].website}
                logo={OperatorsList[index % OperatorsList.length].logo}
                website={OperatorsList[index % OperatorsList.length].website}
                isFounder={false}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Operators;

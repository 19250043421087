import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TeamHeader = (): JSX.Element => {
  const { t } = useTranslation('team-header');

  return (
    <Box py="96px">
      <Flex direction="column" alignItems="center" textAlign="center">
        <Text size="md" color="gold" mb="12px" fontWeight="500">
          {t('header.pretitle')}
        </Text>

        <Heading as="h1" size="md" mb="24px">
          {t('header.title')}
        </Heading>

        <Text size="lg" color="gray.500" w="50%" textAlign="center">
          {t('header.description')}
        </Text>
      </Flex>
    </Box>
  );
};

export default TeamHeader;

import React from 'react';
import { graphql } from 'gatsby';

import { ChakraProvider, Box, Flex } from '@chakra-ui/react';
import theme from '../utils/theme';

import TeamHeader from '../Sections/WhoWeAre/TeamHeader';
import OurTeam from '../Sections/WhoWeAre/OurTeam';
import Partners from '../Sections/WhoWeAre/Partners';
import Operators from '../Sections/WhoWeAre/Operators';

import Banner from '../Sections/Banner';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Seo from '../components/SEO';

const Team = () => (
  <ChakraProvider theme={theme}>
    <Flex direction="column" w="100%">
      <Header />
      <Box px={{ base: '20px', md: '80px' }}>
        <TeamHeader />
        <OurTeam />
      </Box>
      <Operators />
      <Partners />
      <Banner />
    </Flex>
    <Footer />
  </ChakraProvider>
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Team;

export const Head = () => {
  return <Seo title="Landtoken - Conocé el equipo detrás de Landtoken" />;
};

import React from 'react';

import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type PartnerItemProps = {
  logo: string;
  logoHeight: string;
  description: string;
  name: string;
  website: string;
};

const PartnerItem = ({
  logo,
  logoHeight,
  name,
  description,
  website,
}: PartnerItemProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Flex w={{ base: '390', xs: '413px' }} minHeight={135}>
      <Box minW="90px" mr="24px">
        <Image src={logo} h={logoHeight} />
      </Box>
      <Flex direction="column" maxW={{ base: '300px', xs: '320px' }}>
        <Text size="md" mb="4px" fontWeight="500">
          {name}
        </Text>
        <Text size="md" color="gray.700" mb="24px">
          {description}
        </Text>
        <Link href={website} target="_blank">
          <Text textDecoration="underline" color="gray.700">
            {t('visit-web')}
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
};

export default PartnerItem;

// Trust Us
import CompanyTrust1 from '../../../images/companies/agrotoken.svg';
import CompanyTrust2 from '../../../images/companies/adecoagro.png';

const TrustUs = [
  {
    logo: CompanyTrust1,
    descText: 'Infraestructura global de tokenización de recursos naturales.',
    url: 'https://agrotoken.com/',
  },
  {
    logo: CompanyTrust2,
    descText: 'Reconocida empresa de negocios agropecuarios a nivel global.',
    url: 'https://www.adecoagro.com/',
  },
];

export default TrustUs;

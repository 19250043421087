import { Box, Flex, Heading, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CompanyItem from '../../../components/CompanyItem';
import TeamMember from '../../../components/TeamMember';

import Advisors from './advisors';
import Members from './members';
import Founders from './founders';
import TrustUs from './trustUs';

const OurTeam = (): JSX.Element => {
  const { t } = useTranslation('team-members');

  return (
    <Flex direction="column" alignItems="center" mx={{ base: 4, lg: 20 }}>
      <SimpleGrid columns={{ lg: 3 }} spacingX={{ lg: 9 }} spacingY={{ lg: 8 }}>
        {Members.map((member) => (
          <Box key={member.name} mb="64px">
            <TeamMember
              picture={member.picture}
              name={member.name}
              role={t(member.roleText)}
              linkedinURL={member.linkedinURL}
            />
          </Box>
        ))}
      </SimpleGrid>

      <SimpleGrid columns={{ lg: 2 }} spacingX={{ lg: 9 }} spacingY={{ lg: 8 }}>
        {Advisors.map((member) => (
          <Box key={member.name} mb="64px">
            <TeamMember
              picture={member.picture}
              name={member.name}
              role={t(member.roleText)}
              linkedinURL={member.linkedinURL}
            />
          </Box>
        ))}
      </SimpleGrid>

      <Box h="1px" w="100%" px="32px" bg="gray.200" />

      <Flex direction="column" align="center" textAlign="center" my="64px" w="100%">
        <Heading as="h1" size="md" mb="24px">
          {t('trust-us')}
        </Heading>

        <Flex direction={{ base: 'column', md: 'row' }} gap={20} justify="space-between">
          {TrustUs.map((company) => (
            <Box key={company.url} mt="48px">
              <CompanyItem
                logo={company.logo}
                description={company.descText}
                website={company.url}
                big
                isFounder
              />
            </Box>
          ))}
        </Flex>
      </Flex>

      <SimpleGrid columns={{ lg: 3 }} spacingX={{ lg: 9 }} spacingY={{ lg: 8 }}>
        {Founders.map((founder) => (
          <Box key={founder.name} mb="64px">
            <TeamMember
              picture={founder.picture}
              name={founder.name}
              role={t(founder.roleText)}
              description={t(founder.descText)}
            />
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default OurTeam;

// Founders
import Founder1 from '../../../images/team/founder1.svg';
import Founder2 from '../../../images/team/founder2.svg';
import Founder3 from '../../../images/team/founder3.svg';

const Founders = [
  {
    picture: Founder2,
    name: 'Gerardo Bartolomé',
    roleText: 'founder2.role',
    descText: 'founder2.desc',
  },
  {
    picture: Founder3,
    name: 'Ignacio Lartirigoyen',
    roleText: 'founder3.role',
    descText: 'founder3.desc',
  },
  {
    picture: Founder1,
    name: 'Guillermo Simone',
    roleText: 'founder1.role',
    descText: 'founder1.desc',
  },
];

export default Founders;
